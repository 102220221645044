import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import RegionAdd from "@components/regions/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "regions.create"
}

const RegionAddPage = (props) => {
  return (
    <>
      <Seo title="Add Region" />
      <div className="main-wrapper">
        <Sidebar menuActive="settings" />
        <RegionAdd 
          menu="Add Regions" 
          parentPath="regions" 
          pageType="Add"
          additionalParams={AdditionalParams}/>
      </div>
    </>
  )
}

export default RegionAddPage;
