import React, { useState } from "react";
import {Row, Col, Form} from "react-bootstrap";
import {HandleChange, CheckFieldValidity, 
    ResetFormValuesById, GetFormValuesById} from "../../utility/Form";
import CommonAddItemComp from "../common/Add";
import FormFooter from "../common/FormFooter"
import {set, isEmpty, get} from "lodash";

const AddFormControls = (props) => {
    const [formError, setFromError] = useState({});
    const CheckFormFieldValidity = (event) => {
        const FieldError = CheckFieldValidity(event);
        setFromError({...formError, ...FieldError});
    }

    const HandleChangeFunc = (event) => {
        HandleChange(event);
        // CheckFormFieldValidity(event)
    }

    const FormSubmitFunc = async (event, additionalParams = {}) => {
        console.log('FormSubmitFunc', event, additionalParams);
        try {
            const Result = await props.HandleSubmitFunc(event, {
                formId: 'add-region-form',
                ...additionalParams
            });
            console.log('FormSubmitFunc', Result)
            if(get(Result, 'formSubmit.hasError')){
                setFromError(Result.formSubmit.error);
            }
        } catch (error) {
            console.log('FormSubmitFunc error', error);
        }
    }    

    const ResetForm = () => {
        ResetFormValuesById('add-region-form');
    }

    return (
        <>
            <Form id="add-region-form" noValidate onSubmit={(event) => FormSubmitFunc(event, {
                    isSubmit: true
                })}>
                <Row className="my-4">
                    <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                            <label>Region Name *</label>                        
                            <input type="text" name="name" className={`form-control${formError?.name?.hasError ? ' pu-has-control-error': ''}`}
                                onChange={(event)=> HandleChangeFunc(event)} 
                                required={true}                           
                                placeholder="Enter region name here" />
                            {
                            ((formError?.name?.hasError && (formError?.name?.type === 'required')) && <div className="pu-has-error">
                                Region Name is required!
                            </div>)
                            }
                        </div>
                    </Col>                        
                </Row>
                <FormFooter parentPath= {props.parentPath} resetForm={ResetForm} handleSave={FormSubmitFunc}></FormFooter>
            </Form>
        </>
    )
}

const AddRegion = (props) => {
     return <CommonAddItemComp PuFormControlsAdd={
        (HandleSubmitFunc) => {
            return (
                <>
                    <AddFormControls HandleSubmitFunc={HandleSubmitFunc} {...props}/>  
                </>
            )                 
        } 
    } {...props}/>
}

export default AddRegion;